import { GetServerSideProps } from "next";

import HomePage, { Props } from "@/client/components/pages/Home";
import { Collection } from "@/shared/types/collection";
import * as collectionService from "@/server/services/collections";
import { getModuleLogger } from "@/server/lib/logger";

const logger = getModuleLogger("pages/index");

const FEATURED_COLLECTION_ID = "66fdecc518c36ebd447f84c7";
const FEATURED_COLLECTION_OVERRIDES = {
  // imageUrl:
  //   "https://res.cloudinary.com/art-blocks/image/fetch/f_auto,c_limit,w_1024,q_auto/https://media-proxy-development.artblocks.io/0xec5dae4b11213290b2dbe5295093f75920bd2982/65000000.png",
};

export const getServerSideProps: GetServerSideProps<Props> = async () => {
  try {
    // Run all data fetching in parallel
    // These function handle their own errors so they should always resolve
    const [
      featuredCollection,
      upcomingCollectionReleases,
      recentCollectionReleases,
    ] = await Promise.all([
      getFeaturedCollection(),
      getUpcomingReleases(),
      getRecentReleases(),
    ]);

    const props: Props = {};
    if (featuredCollection)
      props.featuredCollection = serializeCollection(featuredCollection);
    if (upcomingCollectionReleases?.length)
      props.upcomingCollectionReleases = upcomingCollectionReleases
        .filter(collectionHasRequiredFields)
        .map(serializeCollection);
    if (recentCollectionReleases?.length)
      props.recentCollectionReleases = recentCollectionReleases
        .filter(collectionHasRequiredFields)
        .map(serializeCollection);

    return {
      props,
    };
  } catch (err) {
    logger.error(err, "HomePage getServerSideProps failed");
    return { props: {} };
  }
};

const getFeaturedCollection = async () => {
  try {
    // Featured collection
    const featuredCollection = await collectionService.readById(
      FEATURED_COLLECTION_ID
    );

    // Add any overriden fields to feature collection
    const featureCollectionWithOverrides: Collection = featuredCollection
      ? {
          ...(featuredCollection as any).toObject(),
          ...FEATURED_COLLECTION_OVERRIDES,
        }
      : null; // needs to be null, cannot serialize undefined

    return featureCollectionWithOverrides;
  } catch (err) {
    logger.error(err, "fetching getFeaturedCollection failed");
    return null;
  }
};

const getUpcomingReleases = () => {
  try {
    return (
      collectionService.list({
        onlyUpcomingMints: true,
        limit: 2,
        sort: "MINT_STARTS_AT_ASC",
      }) || []
    );
  } catch (err) {
    logger.error(err, "fetching getUpcomingReleases failed");
    return [];
  }
};

const getRecentReleases = () => {
  try {
    return (
      collectionService.list({
        onlyPastMints: true,
        limit: 2,
        sort: "MINT_STARTS_AT_DESC",
      }) || []
    );
  } catch (err) {
    logger.error(err, "fetching getRecentReleases failed");
    return [];
  }
};

/**
 * Ensure a collection has minimum fields to display on the homepage
 */
const collectionHasRequiredFields = (collection: Collection) => {
  return !!collection.name && !!collection.imageUrl;
};

const serializeCollection = (collection: Collection) => {
  return JSON.parse(JSON.stringify(collection));
};

export default HomePage;
