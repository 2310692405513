import React from "react";
import { Collection } from "@/shared/types/collection";
import Button from "../frame-design-system/buttons/Button";
import { MintState } from "@/shared/types/collection";
import { getArtBlocksPlatformLockup } from "@/shared/artblocks";
import { Platform, isArtBlocksPlatform } from "@/shared/platforms";
import Countdown from "react-countdown";

type CollectionData = {
  collection: Collection;
};

const countDownRenderer = ({ days, hours, minutes }: any) => {
  // Render a countdown
  return (
    <span>
      {days}d {hours}h {minutes}m
    </span>
  );
};
const PlatformBadgePill = ({ collection }: CollectionData) => {
  const { platformData, stats, mintState, supplyMax, mintStartsAt } =
    collection;
  let collectionDetails;

  if (!collection || !isArtBlocksPlatform(platformData)) return null;
  const lockupImageUrl = getArtBlocksPlatformLockup(platformData.kind);
  if (mintState === MintState.Upcoming) {
    collectionDetails = "Opens in ";
  } else if (mintState === MintState.InProgress) {
    collectionDetails = `${stats?.supplyTotal || 0} / ${supplyMax?.toLocaleString() || "--"} Minted`;
  } else {
    collectionDetails = "";
  }
  return (
    <div className="inline-flex items-center py-1">
      <img src={lockupImageUrl} className="h-5" alt="featured" />
      <span className="ml-2 text-p-lg text-white/80">
        {" "}
        - {collectionDetails}
      </span>
      {mintState === MintState.Upcoming ? (
        <p className="pr-2 text-p-xs text-neutral-600 dark:text-neutral-300">
          <Countdown date={mintStartsAt} renderer={countDownRenderer} />
        </p>
      ) : null}
    </div>
  );
};

const CollectionFeatureCard = ({ collection }: CollectionData) => {
  if (!collection) return null;
  const { name, artistName, imageUrl, platform, slug, _id } = collection;
  let buttonText =
    platform === Platform.ArtBlocksCurated ? "Discover on Curated" : "Explore";

  // use this logic when you want to display multiple image format for the hero. Supply the images within the images array below
  const images: any = [];
  // const hasMultipleImages = images && images.length > 1;
  const hasMultipleImages = false;

  return (
    <div
      className={`relative  flex ${
        hasMultipleImages ? "flex-col" : "items-center justify-center"
      } w-full h-full pt-[80px]`}
    >
      {/* Background image with gradient */}
      <div
        className="absolute inset-0 bg-cover bg-center"
        style={{ backgroundImage: `url(${imageUrl})` }}
      >
        {/* Gradient overlay */}
        <div
          className="absolute inset-0"
          style={{
            background:
              "radial-gradient(ellipse at center, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0.95) 100%)",
          }}
        ></div>
      </div>

      {/* Content */}
      <div
        className={`2xl:container relative flex ${
          hasMultipleImages ? "flex-col" : "flex-col md:flex-row"
        } w-full h-full items-center justify-center p-4 sm:p-8 md:p-12 lg:p-16 overflow-hidden max-w`}
      >
        {/* Text content */}
        <div
          className={`flex flex-col ${hasMultipleImages ? "w-full items-center text-center mb-8" : "w-full md:w-1/2 md:text-left text-center md:items-start items-center sm:mt-12 md:mt-0"}`}
        >
          <div>
            <h1 className="text-4xl md:text-display-l font-bold text-white leading-tight">
              {name}
            </h1>
            <p className="mt-2 text-lg md:text-xl text-white/80">
              {artistName}
            </p>
            <div className="my-6">
              <PlatformBadgePill collection={collection} />
            </div>
            <div className="sm:max-w-[16em]">
              <Button
                as="Link"
                type="primary"
                size="large"
                href={`/collections/${slug || _id}`}
                className={`mt-4 ${hasMultipleImages ? "my-4 px-6 py-3 text-sm font-medium rounded" : ""} bg-white text-black hover:bg-white/90`}
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>

        {/* Image(s) */}
        {hasMultipleImages ? (
          <>
            <div className="w-full md:w-1/2 flex md:hidden items-center justify-center mt-6 md:mt-0">
              <img
                src={imageUrl}
                alt={name}
                className="w-full h-auto max-h-[50vh] object-contain rounded-lg"
              />
            </div>

            <div className="w-full hidden md:flex justify-center space-x-4 overflow-x-auto">
              {images.slice(0, 5).map((img: any, index: any) => (
                <div
                  key={index}
                  className="flex-shrink-0 flex items-center mt-20"
                >
                  <img
                    src={img}
                    alt={`${name} - Image ${index + 1}`}
                    className="object-cover rounded-lg transition-transform duration-300 hover:scale-110 w-64 h-64"
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="w-full md:w-1/2 flex items-center justify-center mt-8 md:mt-0">
            <img
              src={imageUrl}
              alt={name}
              className="-mb-12 md:mb-0 w-full h-auto max-h-[60vh] object-contain rounded-lg"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { CollectionFeatureCard };
